/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-11 11:27:39
 * @LastEditors: hutian
 * @LastEditTime: 2021-05-14 16:58:53
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

// 客户
export const listDealerCustomerInfo = params => axios({
    url: '/api/dealer/customer/dealerCustomerInfo/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

// 事务所查看客户
export const listFirmCustomerInfo = params => axios({
  url: '/api/dealer/customer/dealerCustomerInfo/firmList',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const addDealerCustomerInfo = params => axios({
    url: '/api/dealer/customer/dealerCustomerInfo/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerCustomerInfo = params => axios({
    url: '/api/dealer/customer/dealerCustomerInfo/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerCustomerInfo = params => axios({
    url:'/api/dealer/customer/dealerCustomerInfo/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerCustomerInfo = params => axios({
    url: '/api/dealer/customer/dealerCustomerInfo/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})


//  任务
export const addDealerCustomerTask = params => axios({
    url: '/api/dealer/customer/dealerCustomerTask/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerCustomerTask = params => axios({
    url: '/api/dealer/customer/dealerCustomerTask/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerCustomerTask = params => axios({
    url:'/api/dealer/customer/dealerCustomerTask/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerCustomerTask = params => axios({
    url: '/api/dealer/customer/dealerCustomerTask/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})


// 历史跟进
export const listDealerHistoryFollowUp = params => axios({
    url: '/api/dealer/customer/dealerHistoryFollowUp/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerHistoryFollowUp = params => axios({
    url: '/api/dealer/customer/dealerHistoryFollowUp/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerHistoryFollowUp = params => axios({
    url: '/api/dealer/customer/dealerHistoryFollowUp/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delDealerHistoryFollowUp = params => axios({
    url:'/api/dealer/customer/dealerHistoryFollowUp/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerHistoryFollowUp = params => axios({
    url: '/api/dealer/customer/dealerHistoryFollowUp/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})